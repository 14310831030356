import React, { useEffect, useMemo } from "react";

import $ from "jquery";
import "round-slider/src/roundslider.css";
import "../styles/slideroverwrites.css";
import "round-slider";

const Slider = ({ truckHvo, truck, train, ship, sliderChange }) => {

  const numSliders = useMemo(() => [truckHvo, truck, train, ship].filter(Boolean).length, [truck, truckHvo, train, ship]);
  const sliderOptions = useMemo(() => [
    { name: 'truckHvo', stroke: '#0c4b27', range: null, display: truckHvo },
    { name: 'truck',    stroke: '#d4181f', range: null, display: truck },
    { name: 'train',    stroke: '#313131', range: null, display: train },
    { name: 'ship',     stroke: '#6a8da7', range: null, display: ship },
    { name: '',         stroke: 'lightgrey',    range: null, display: !truckHvo && !truck && !train && !ship}
  ], [truck, truckHvo, train, ship]);

  const firstSlider = useMemo(() => [truckHvo, truck, train, ship].findIndex(Boolean), [truckHvo, truck, train, ship]);
  const firstSliderOptions = useMemo(() => sliderOptions[firstSlider] || sliderOptions[4], [firstSlider, sliderOptions]);

  useEffect(() => {
    $("#slider").roundSlider({
      sliderType: "default",
      animation: false,
      radius: 250,
      width: 45,
      value: "0",
      svgMode: true,
      handleShape: "round",
      handleSize: 40,
      step: 1,
      pathColor: firstSliderOptions.stroke,
      startAngle: 90,
      showTooltip: false,
      borderWidth: 1,
      borderColor: "white",

      create: function () {
        const o = this.options;

        this.sliders = (() => {
          const sliders = {};
          if (ship && firstSliderOptions.name !== 'ship')     sliders.ship     = sliderOptions[3]
          if (train && firstSliderOptions.name !== 'train')    sliders.train    = sliderOptions[2];
          if (truck && firstSliderOptions.name !== 'truck')    sliders.truck    = sliderOptions[1];
          if (truckHvo && firstSliderOptions.name !== 'truckHvo') sliders.truckHvo = sliderOptions[0];
          return sliders;
        })();

        let index = 2;
        let range = 100 / (numSliders || 1);
        const ranges = [];
        for (const slider of Object.values(this.sliders)) {
          
          this._createHandle(index);
          this.setValue(range, index);
          slider.range = this.$createSVG("path", {
            stroke: slider.stroke,
            "stroke-width": o.width,
          });

          ranges.push(slider.range);

          index += 1;
          range += 100 / numSliders;
          
        }

        this.$append(document.querySelector("svg"), ranges);
        this.created = true;
      },

      valueChange: function (e) {
        var o = this.options;
        if (!this.created) return;

        const values = {};
        let sum = 0;
        
        let i = 1;
        for (const slider of Object.values(this.sliders)) {
          const value = this[`_handle${i+1}`].value - sum;
          values[slider.name] = value;
          sum += value;


          const d = this.$drawPath(
            o.radius,
            o.radius - o.width / 2 - 1,
            this[`_handle${i}`].angle,
            this[`_handle${++i}`].angle,
            o.radius - o.width / 2 - 1,
            "none"
          );

          this.$setAttribute(slider.range, { d });
        }

        values[firstSliderOptions.name] = 100 - sum;

        sliderChange({
          truckHvo: values.truckHvo || 0,
          truck: values.truck || 0,
          train: values.train || 0,
          ship: values.ship || 0,
        });
      },

      beforeValueChange: function (e) {
        if (!this.created) return;
        
        const index = this._active - 1;
        const value = e.value;
        const max = e.options.max;

        const handles = []
        for (let i = 1; i <= numSliders; i++) {
          handles.push(this[`_handle${i}`]?.value);
        }
        
        // here do the validation so that handle1 doesn't move and each handle won't cross other
        if (
          index > 0 &&
          value >= handles[index - 1] + 1 &&
          value <= (handles[index + 1] - 1 || max - 1)
        ) {
          return true; // these are the valid cases
        } else {
          return false; // otherwise restrict the value
        }
      },
    });

    var sliderObj = $("#slider").data("roundSlider");
    sliderObj._raiseValueChange();

    // clean up the plugin when the component unmounts
    return () => {
      sliderObj.destroy();
    };
  }, [
    firstSliderOptions.name,
    firstSliderOptions.stroke,
    numSliders, 
    sliderChange, 
    sliderOptions, 
    truck, truckHvo, train, ship
  ]);

  return <div id="slider" style={{ margin: "auto", marginTop: "20x" }} />;
};

export default Slider;
