import styled from "styled-components";

import TruckPng from "../images/icons/truck.png";
import TrainPng from "../images/icons/train.png";
import ShipPng from "../images/icons/ship.png";
import PinPng from "../images/icons/map-pin.png";
import ClosePng from "../images/icons/close.png";
import SwapPng from "../images/icons/swap-arrows.png";

const TransportIcon = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-image: url(${({icon}) => icon});
  background-size: ${({size}) => size || 20}px;
  background-repeat: no-repeat;
  background-position: ${({x}) => x || 6}px ${({y}) => y || 6}px;
`;

const Icon = styled.div`
    display: inline-block;
    background-color: transparent;
    background-image: url(${({icon}) => icon});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: ${({w, h}) => w || h || 20}px;
    height: ${({w, h}) => h || w || 20}px;
`;


const Icons = ({ children }) => ({children});

const Truck = ({ bgColor }) => (
    <TransportIcon icon={TruckPng} bgColor={bgColor} x={5.5} y={8} />
);

const Train = ({ bgColor }) => (
    <TransportIcon icon={TrainPng} bgColor={bgColor} size={16} x={7} y={5} />
);

const Ship = ({ bgColor }) => (
    <TransportIcon icon={ShipPng} bgColor={bgColor} x={5} y={8} />
);

const SwapArrows = () => (
    <Icon icon={SwapPng} w={30} h={21} />
    // <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
    //     <path id="Icon_material-compare-arrows" data-name="Icon material-compare-arrows" d="M13.515,21H3v3H13.515v4.5l5.985-6-5.985-6V21Zm8.97-1.5V15H33V12H22.485V7.5l-5.985,6Z" 
    //         transform="translate(-3 -7.5)" fill={color}
    //     />
    // </svg>
);

const MapPin = () => (
    <Icon icon={PinPng} w={18} h={26} />
    // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="26" viewBox="0 0 18 26">
    //     <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M16.5,3a9.044,9.044,0,0,0-9,9.1c0,6.825,9,16.9,9,16.9s9-10.075,9-16.9A9.044,9.044,0,0,0,16.5,3Zm0,12.35a3.25,3.25,0,1,1,3.214-3.25A3.233,3.233,0,0,1,16.5,15.35Z" transform="translate(-7.5 -3)" 
    //         fill={color}
    //     />
    // </svg>
);

// X icon
const Close = () => (
    <Icon icon={ClosePng} w={13} h={13} />
    // <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
    //     <path id="Icon_ionic-md-close" data-name="Icon ionic-md-close" d="M20.523,8.823l-1.3-1.3-5.2,5.2-5.2-5.2-1.3,1.3,5.2,5.2-5.2,5.2,1.3,1.3,5.2-5.2,5.2,5.2,1.3-1.3-5.2-5.2Z" transform="translate(-7.523 -7.523)"/>
    // </svg>
);

Icons.Truck = Truck;
Icons.Train = Train;
Icons.Ship = Ship;
Icons.SwapArrows = SwapArrows;
Icons.MapPin = MapPin;
Icons.Close = Close;

export default Icons;
