import "./App.css";
import "./styles/fonts.css";
import styled from "styled-components";
import Footprint from "./components/Footprint";
import { useMemo } from "react";
import { useScale } from "./useScale";

const AppWrapper = styled.div`
  padding: 0px;
  font-family: DIN-Medium;
  color: #313131;
  margin: ${({ margin }) => margin};
  overflow: hidden;
`;

function App() {
  
  const { scale } = useScale();

  const isIframe = useMemo(() => window.top !== window.self, []);
  const margin = useMemo(() => isIframe || scale < 1 ? 0 : '10px 0', [isIframe]);

  return (
    <AppWrapper margin={margin}>
      <Footprint isIframe={isIframe}/>
    </AppWrapper>
  );
}

export default App;
